import React, { Suspense } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout, Spin } from 'antd'
// import { Link } from 'react-router-dom'
import { withRouter } from 'router'

import classNames from 'classnames'
import Sidebar from 'components/cleanui/layout/Sidebar'
// import SupportChat from 'components/cleanui/layout/SupportChat'
import { history } from 'index'

import styles from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = ({
  children,
  // logo,
  isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
}) => {
  return (
    <Layout>
      <Layout.Content className={classNames(styles.containerWrapper)}>
        <Suspense fallback={<Spin />}>
          <Sidebar />
        </Suspense>
        {/* <SupportChat /> */}
        <div
          className={classNames(`${styles.container}`, {
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
            [styles.white]: authPagesColor === 'white',
            [styles.gray]: authPagesColor === 'gray',
          })}
          style={{
            backgroundImage:
              authPagesColor === 'image' ? 'url(resources/images/content/photos/7.jpg)' : '',
          }}
        >
          <div
            className={classNames(`${styles.topbar}`, 'navbar', {
              [styles.topbarGray]: isGrayTopbar,
            })}
          >
            <div
              className={classNames(styles.logoContainer, 'navbar-brand')}
              role="button"
              tabIndex="0"
              onFocus={(e) => {
                e.preventDefault()
              }}
              onKeyPress={() => {}}
              onClick={() => {
                history.push('/')
              }}
            >
              <Link className="brand-logo" to="/home">
                OfficeMail
              </Link>
            </div>
            {/*
            <div className="d-none d-sm-block">
              <span className="mr-2">Don&#39;t have an account?</span>
              <Link to="/auth/register" className="font-size-16 kit__utils__link">
                Sign up
              </Link>
            </div>
            */}
          </div>
          <div className={styles.containerInner}>{children}</div>
          <div className="mt-auto pb-5 pt-5">
            {/*
            <ul
              className={`${styles.footerNav} list-unstyled d-flex mb-0 flex-wrap justify-content-center`}
            >
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Compliance
                </a>
              </li>
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Support
                </a>
              </li>
              <li>
                <a href="#" onClick={e => e.preventDefault()}>
                  Contacts
                </a>
              </li>
            </ul>
            */}
            <div className="text-center">
              Copyright © 2013-2024 9Folders, Inc. |{' '}
              <a
                href="https://www.9folders.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
