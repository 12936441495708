import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'router'

class FooterDef extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col logo">
              <img src={require('assets/ic/brand_logo/brand-logo.png')} alt="OfficeMail" />
            </div>
            <div className="col menu-link">
              <ul>
                <li className="label">Help</li>
                <li>
                  <Link to="/pricing">Pricing</Link>
                </li>
                <li>
                  {/* <Link to="/help-center">Contact Sales</Link> */}
                  <a
                    href="https://re-work.zendesk.com/hc/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Help Center
                  </a>
                </li>
                <li>
                  {/* <Link to="/help-center">Contact Sales</Link> */}
                  <a href="mailto:sales@9folders.com" target="_blank" rel="noopener noreferrer">
                    Contact Sales
                  </a>
                </li>
              </ul>
              <ul>
                <li className="label">Features</li>
                <li>
                  <Link to="/mail">Mail</Link>
                </li>
                <li>
                  <Link to="/calendar">Calendar</Link>
                </li>
                <li>
                  <Link to="/contacts">Contacts</Link>
                </li>
                <li>
                  <Link to="/notes-todo">To Do</Link>
                </li>
              </ul>
              <ul>
                <li className="label">Downloads</li>
                <li>
                  <Link to="/download">OfficeMail Pro for Android</Link>
                </li>
                <li>
                  <Link to="/download">OfficeMail Pro for iOS</Link>
                </li>
                <li>
                  <Link to="/download">OfficeMail Enterprise for Android</Link>
                </li>
                <li>
                  <Link to="/download">OfficeMail Enterprise for iOS</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col terms">
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
              {/* <Link to="/help-center">Contacts</Link> */}
              <a href="mailto:sales@9folders.com" target="_blank" rel="noopener noreferrer">
                Contacts
              </a>
            </div>
            <div className="col license">
              <span>Ⓒ 2024 9Folders, Inc.</span>
              <img src={require('assets/ic/brand_logo/logo_footer_gray.png')} alt="9Folders" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const Footer = withRouter(FooterDef)

export default Footer
